import React from 'react';


const FilterComponent = (props) => {
    const { handleChange, handleNtwk } = props;

    return (
        <div className='row'>
            <div className='offset-lg-6 col-lg-2 offset-sm-6 col-sm-2 offset-1 col-4 pr-sm-5 pl-xs-5 pr-xs-5'>
                <select onChange={handleNtwk} className='filter_text'>
                    <option value="">-All-</option>
                    <option value="MTN">MTN</option>
                    <option value="GLO">GLO</option>
                    <option value="AIRTEL">AIRTEL</option>
                    <option value="9MOBILE">9MOBILE</option>
                </select>
            </div>
            <div className='offset-lg-0 col-lg-4 offset-sm-0 col-sm-4 offset-0 col-7 pr-sm-20 pl-xs-5 pr-xs-15'>
                <input
                className='filter_text'
                onChange={handleChange}
                placeholder={`Filter here`}
                />
            </div>
        </div>
    );
};

export default FilterComponent;
