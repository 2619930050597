import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes, useNavigate, Redirect } from 'react-router-dom';
import PrivateRoutes from "../../utils/PrivateRoutes";
import Login from "../Login";
import Index from "../Dashboards/Index";
import NotFound from "../NotFound/NotFound";
import Settings from "../Dashboards/Settings";
import $ from 'jquery';
import Swal from "sweetalert2";
import Services from "../Dashboards/Services/Services";
import PricePoint from "../Dashboards/PricePoint/PricePoint";
import Subscribers from "../Dashboards/Subscribers/Subscribers";
import UnSubscribers from "../Dashboards/Subscribers/UnSubscribers";
import ChargingSync from "../Dashboards/ChargingSync/ChargingSync";
import ChargingUnSync from "../Dashboards/ChargingSync/ChargingUnSync";
import Demands from "../Dashboards/Demands/Demands";
import OnDemands from "../Dashboards/Demands/OnDemands";
import RevenueReport from "../Dashboards/RevenueReport/RevenueReport";



export default function RouterComponent(){

    const userData = JSON.parse(localStorage.getItem('userDataFs'));
    const navigate = useNavigate();

    const objectToFormData = (obj) => {
        const formData = new FormData();      
        Object.entries(obj).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return formData;
    }

    
    const formatDate = (dateString) => {
        const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          //day: '2-digit'
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;
    };
    
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    const capitalizeWords = (string) => {
        const words = string.split(' ');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    }

    const errorFunction = (error) => {
        if(error.response.status.toString() === "400" || error.response.status.toString() === "401"){
            Swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error"
            });
        }else{
            Swal.fire({
                title: "Error",
                text: error,
                icon: "error"
            });
        }
    }


    useEffect(() => {
        var flapt_window = $(window);
        var pageWrapper = $(".flapt-page-wrapper");
        var sideMenuArea = $(".flapt-sidemenu-wrapper");
        var fullScreen = $("body")[0];

        
        // :: Preloader Active Code
        flapt_window.on('load', function () {
            $('#preloader').fadeOut('slow', function () {
                $(this).remove();
            });
        });
    }, []);

    const isAuthExpired = (responseJSON) => {
        if(responseJSON.status.toString() === "900" || (responseJSON.status.toString() === "300" && responseJSON.message === "Authentication Failed")){
            localStorage.removeItem('userDataFs');
            navigate("/login");
            return true;
        }else{
            return false;
        }
    }
    


    return (
        <Routes>
            <Route path="" element={<Login objectToFormData={objectToFormData} />} />
            <Route path="/login" element={<Login objectToFormData={objectToFormData} />} />

            <Route path="*" element={<NotFound />} />

            <Route path="/dashboard" element={
                <PrivateRoutes redirectTo="/login">
                    <Index Helmet={Helmet} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} objectToFormData={objectToFormData} />
                </PrivateRoutes>
            }/>

            <Route path="/charging-logs" element={
                <PrivateRoutes redirectTo="/login">
                    <ChargingSync Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/charging-unsync-logs" element={
                <PrivateRoutes redirectTo="/login">
                    <ChargingUnSync Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/settings" element={
                <PrivateRoutes redirectTo="/login">
                    <Settings Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/service-list" element={
                <PrivateRoutes redirectTo="/login">
                    <Services Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/revenue-report" element={
                <PrivateRoutes redirectTo="/login">
                    <RevenueReport Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            {/* <Route path="/products" element={
                <PrivateRoutes redirectTo="/login">
                    <PricePoint Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/> */}

            <Route path="/subscribers" element={
                <PrivateRoutes redirectTo="/login">
                    <Subscribers Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/unsubscribers" element={
                <PrivateRoutes redirectTo="/login">
                    <UnSubscribers Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/demands" element={
                <PrivateRoutes redirectTo="/login">
                    <Demands Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            <Route path="/on-demands" element={
                <PrivateRoutes redirectTo="/login">
                    <OnDemands Helmet={Helmet} objectToFormData={objectToFormData} capitalizeWords={capitalizeWords} errorFunction={errorFunction} isAuthExpired={isAuthExpired} />
                </PrivateRoutes>
            }/>

            

        </Routes>
    );
}