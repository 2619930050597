import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouterComponent from './Components/Routes/RouterComponent';

function App() {

  return (
    <BrowserRouter>
      <RouterComponent /> {/* useNavigate is used in this component */}
    </BrowserRouter>
  );
}

export default App;
