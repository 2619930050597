import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import WhiteListTable from "./WhiteListTable";
import Nulls from './Nulls';
import axios from "axios";
import { format } from "date-fns";


const tabData = [
  { name: 'Whitelist Account', isActive: true },
  { name: 'View Request', isActive: false },
  // { name: 'Tab 3', isActive: false }
];



function Tabs({ activeTab, changeTab }) {
  return (
    <ul className="nav nav-tabs settingsTab mb-20">
      {tabData.map((tab) => (
        <Tab key={tab.name} data={tab} isActive={activeTab.name === tab.name} handleClick={() => changeTab(tab)} />
      ))}
    </ul>
  );
}
  
function Tab({ data, isActive, handleClick }) {
    return (
      <li onClick={handleClick} className={isActive ? "active" : null}>
        <a href="#">{data.name}</a>
      </li>
    );
}
  

function Content({ activeTab, Swal, API_ROUTES, objectToFormData, emailValidatio, userData, errorFunction, emailValidation, capitalizeWords, headers, headers2, changeTab, whitelistBtn, setWhitelistBtn, register3, reset3, handleSubmit3, errors3, isSubmitting3, isValid3, isEditing, setIsEditing }) {
  
  const { register: register4, reset: reset4, handleSubmit: handleSubmit4, formState: {errors: errors4, isSubmitting: isSubmitting4, isValid: isValid4} } = useForm({mode: 'onChange'});

  const [userDatas, setUserDatas] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 15;
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [actions, setActions] = useState('');

  const editIPAddr = (ipAddr, emails) => {
    const firstTab = tabData[0];
    changeTab(firstTab);

    setTimeout(() => {
      reset3({
        email: emails,
        ip: ipAddr,
      });
      setWhitelistBtn('Update Whitelist');
      setIsEditing(1);
    }, 50);
  }


  const columns = [
    {
      name: 'Sn',
      selector: (row, index) => index + 1,
    },
    {
      name: 'IP Addresses',
      selector: row => row,
    },
    {
      name: 'Action',
      selector: row => (
        <>
          <i className='fa fa-edit' onClick={() => editIPAddr(row, userData?.responseDetails?.email)} style={{ fontSize:'22px', color:'#069', cursor:'pointer' }}></i> &nbsp;&nbsp;

          <i className='fa fa-trash' onClick={() => deleteIPAddr(row)} style={{ fontSize:'22px', color:'red', cursor:'pointer' }}></i>
        </>
      ),
    }
  ];

  const validateIPAddress = (ipAddress) => {
    const ipv4Pattern = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/;
    const ipv6Pattern = /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i;
    if (ipv4Pattern.test(ipAddress) || ipv6Pattern.test(ipAddress)) {
      return true;
    }
    return false;
  }


  const handleWhitelist = async (data) => {
    const ipaddr = data.ip;
    let customData = {};
    let url = API_ROUTES.IPREQUEST;
    let caption2 = "Whitelist";
    customData = {
      ip: data.ip,
      type: data.actions
    }

    if(isEditing === 1){
      url = API_ROUTES.UPDATE_WHITELIST;
      caption2 = "Update";
    }

    if(data.actions === "update"){
      customData = {
        old_ip: data.ip,
        type: data.actions
      }
    }

    if(validateIPAddress(ipaddr)){
        try {
            const result = await Swal.fire({
                title: `${caption2} this IP?`,
                text: 'This can always be undone, proceed?',
                icon: 'question',
                iconHtml: '?',
                showCancelButton: true,
                confirmButtonColor: '#337ab7',
                cancelButtonColor: '#ccc',
                confirmButtonText: `Yes, Proceed`,
            });
              
            if(result.isConfirmed) {
                Swal.fire({
                    title: 'Processing...',
                    text: "Please wait a second for a response...",
                    icon: 'success',
                    showConfirmButton: false,
                    confirmButtonColor: '#027937',
                    cancelButtonColor: '#d33',
                });
                const response = await fetch(url, {
                    method: "POST",
                    body: objectToFormData(customData),
                    headers: {'token': userData?.responseDetails?.token},
                });
                const responseJSON = await response.json();
    
                if(responseJSON.status.toString() === "200"){
                    Swal.fire({
                        title: "Successful",
                        html: `The IP with the email <b>${data.ip}</b> has been sent`,
                        icon: "success",
                        timer: 4000
                    });
                    getIPAddrs();
                    setTimeout(() => {
                        reset3();
                    }, 500);
                }else{
                    if(responseJSON.status.toString() === "500"){
                        Swal.fire({
                            title: "Error",
                            text: responseJSON.required_fields,
                            icon: "error",
                        });
                        return;
                    }
                    Swal.fire({
                        title: "Error",
                        text: responseJSON.message,
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            if(error.response){
                errorFunction(error);
            }
        }
    }else{
        Swal.fire({
            title: "Error",
            text: "The IP address you entered was invalid",
            icon: "error",
        });
    }
  }

  const getIPAddrs = async () => {
    try {
      const response = await fetch(API_ROUTES.VIEW_REQUESTS, {
          method: "POST",
          headers: {'token': userData?.responseDetails?.token},
      });
      const responseJSON = await response.json();

      if(responseJSON.status.toString() === "200"){
        setUserDatas(responseJSON.data);
        setIsLoading(false);
        setShowTable(true);
        return "success";
      }else{
        if(responseJSON.status.toString() === "500"){
          return responseJSON.message;
        }
        return responseJSON.message;
      }
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    getIPAddrs();
}, []);


  /*const handleWhitelistData = async (data) => {
    // console.log(data);
    // return;
    setIsLoading(true);
    try {
      const get_ip = await getIPAddrs(data, {headers2});
      // console.log(get_ip);
      if (get_ip !== "success") {
        Swal.fire({
          title: "Error",
          text: get_ip,
          icon: "error",
        });
      }
    } catch (error) {
      // Handle any errors that may occur during the execution of getIPAddrs
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "An error occurred",
        icon: "error",
      });
    }
  }*/


  const deleteIPAddr = async (ipaddr) => {
    const data = {
      ip: ipaddr,
      email: userData?.responseDetails?.email,
      token: userData?.responseDetails?.token,
    }
    try {
      const result = await Swal.fire({
          title: `Delete this IP?`,
          text: 'This cannot be be undone, proceed?',
          icon: 'question',
          iconHtml: '?',
          showCancelButton: true,
          confirmButtonColor: 'red',
          cancelButtonColor: '#ccc',
          confirmButtonText: `Yes, Proceed`,
      });
        
      if(result.isConfirmed) {
        Swal.fire({
          title: 'Processing...',
          text: "Please wait a second for a response...",
          icon: 'success',
          showConfirmButton: false,
          confirmButtonColor: '#027937',
          cancelButtonColor: '#d33',
        });
        const response = await fetch(API_ROUTES.DEL_WHITELIST, {
          method: "POST",
          body: objectToFormData(data)
        });
        const responseJSON = await response.json();
  
        if(responseJSON.status.toString() === "200"){
          setUserDatas(responseJSON.data);
          setIsLoading(false);
          Swal.fire({
            title: "Deleted",
            text: "IP has been deleted",
            icon: "success",
            timer: 2000
          });
        }else{
          if(responseJSON.status.toString() === "500"){
            Swal.fire({
              title: "Error",
              text: responseJSON.required_fields,
              icon: "error",
            });
            return;
          }
          Swal.fire({
            title: "Error",
            text: responseJSON.message,
            icon: "error",
          });
        } 
      }
    } catch (error) {
      if(error.response){
        errorFunction(error);
      }
    }
  }

  const handleActionChange = (event) => {
    const actns = event.target.value;
    setActions(actns); 
}
  




  return (
    <div>
      {activeTab.name === 'Whitelist Account' ? (
        <form className="row g-3" onSubmit={handleSubmit3(handleWhitelist)}>
          {/* <input value={userData?.responseDetails?.token} name="token" type="hidden" {...register3("token")} /> */}
          <div className="card_ pb-20">
            <div className="pl-xs-0 pr-xs-0 rounded-3">
                <div className="col-md-12 mb-20 mb-xs-30">
                  <h6 style={{color:'#555', fontSize:'16px', lineHeight:'24px'}}>Enter IP and select action to submit to us so that we can do the needful.</h6>
                </div>

                {/* <div className="col-md-12 mb-15">
                  <label className="form-label" htmlFor="email">Email Address</label>
                  {errors3.email && <label className="errors">{errors3.email?.message}</label>}

                  <input className="form-control" id="email" name="email" type="email" placeholder="Enter Email Address"
                  {...register3("email", { 
                      ...emailValidation
                      })}
                  />
                </div> */}

                <div className="col-md-12 mb-15">
                  <label className="form-label" htmlFor="ipaddr">IP Address</label>
                  {errors3.ip && <label className="errors">{errors3.ip?.message}</label>}

                  <input className="form-control" id="ip" name="ip" type="text" placeholder="Enter IP Address"
                    {...register3(isEditing === 0 ? "ip" : "old_ip", { 
                      required: "IP address is required"
                    })}
                  />
                </div>

                <div className="col-md-12 mb-15">
                  <label className="form-label" htmlFor="ipaddr">Select Action</label>

                  <select className="form-select form-control_" name="actions" onChange={handleActionChange}
                    {...register3("actions")}>
                      <option value="add" selected>Add to whitelist</option>
                      <option value="update">Update whitelist</option>
                  </select>

                </div>
                
                <div className="offset-md-7 col-md-5 text-end pr-10 mt-15 mt-md-30 mb-30">
                  <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting3 || !isValid3} style={{ opacity: isSubmitting3 ? '0.5' : 1 }}>{whitelistBtn}</button>
                </div>
            </div>
          </div>
        </form>
      ) : null}

      {activeTab.name === 'View Request' ? (
        // <form className="row g-3" onSubmit={handleSubmit4(handleWhitelistData)}>
        //   <input value={userData?.responseDetails?.token} name="token" type="hidden" {...register4("token")} />
        //   <div className="card_ pb-20">
        //     <div className="pl-xs-0 pr-xs-0 rounded-3">
        //         <div className="col-md-12 mb-10 mb-sm-30 mb-xs-30">
        //           <h6 style={{color:'#555', fontSize:'16px'}}>Enter email address to get data</h6>
        //         </div>

        //         <div className="col-md-12 mb-15">
        //           <label className="form-label" htmlFor="email">Email Address</label>
        //           {errors4.email && <label className="errors">{errors4.email?.message}</label>}

        //           <input className="form-control" id="email" name="email" type="email" placeholder="Enter Email Address"
        //           {...register4("email", { 
        //               ...emailValidation
        //               })}
        //           />
        //         </div>
                
        //         <div className="offset-md-8 col-md-4 text-end pr-10 mt-15 mt-md-30 mb-30">
        //           <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting4 || !isValid4} style={{ opacity: isSubmitting4 ? '0.5' : 1 }}>Enter</button>
        //         </div>

        //         {
        //           showTable &&
        //           <WhiteListTable userDatas={userDatas} users={users} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} />
        //         }
        //     </div>
        //   </div>
        // </form>


        <>
          {/* <form className="row g-3" onSubmit={handleSubmit4(handleWhitelistData)}> */}
          
          {/* <form className="row g-3">
            <input value={userData?.responseDetails?.token} name="token" type="hidden" {...register4("token")} />
            <div className="card_ pb-20">
              <div className="pl-xs-0 pr-xs-0 rounded-3">

                  <div className="col-md-12 mb-15">
                    <label className="form-label" htmlFor="email">Enter IP</label>
                    {errors4.ip && <label className="errors">{errors4.ip?.message}</label>}

                    <input className="form-control" id="ip" name="ip" type="text" placeholder="Enter IP Address"
                    {...register4("ip", { 
                        required: "IP address is required"
                        })}
                    />
                  </div>
                  
                  <div className="offset-md-8 col-md-4 text-end pr-10 mt-15 mt-md-30 mb-30">
                    <button className="btn btn-primary btn-primaryi" type="submit" disabled={isSubmitting4 || !isValid4} style={{ opacity: isSubmitting4 ? '0.5' : 1 }}>Enter</button>
                  </div>

                  {
                    showTable &&
                    <WhiteListTable userDatas={userDatas} users={users} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} />
                  }
              </div>
            </div>
          </form> */}

          <table className="table table-dashed mt-30 mb-30">
            <thead>
              <tr>
                  <th scope="col">SN</th>
                  <th scope="col">API Keys</th>
                  <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              {
                userDatas.length !== 0 && userDatas.map((userData, index) => (
                  <tr>
                    <th scope="row">{index+1}</th>
                    <td>{userData.ip}</td>
                    <td>{userData.created_at ? format(new Date(userData.created_at), 'MMM d, yyyy h:mma') : ''}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
}


function MyTabs(props) {
    const [isEditing, setIsEditing] = useState(0);
    const [activeTab, setActiveTab] = useState(tabData[0]);
    const [whitelistBtn, setWhitelistBtn] = useState('Whitelist Account');
    const {Swal, API_ROUTES, objectToFormData, emailValidation, userData, errorFunction, capitalizeWords, headers} = props;

    const { register: register3, reset: reset3, handleSubmit: handleSubmit3, formState: {errors: errors3, isSubmitting: isSubmitting3, isValid: isValid3} } = useForm({
      mode: 'onChange',
      defaultValues: {
        email: '',
        ip: '',
      }
    });
  
    const changeTab = (tab) => {
      setActiveTab(tab);
      if(tab.name === "Whitelist Account"){
        reset3({
          email: '',
          ip: '',
        });
        setWhitelistBtn('Whitelist Account');
        setIsEditing(0);
      }
      if(tab.name === "Whitelist Account"){
        reset3({
          email: '',
          ip: '',
        });
        setWhitelistBtn('Whitelist Account');
        setIsEditing(0);
      }
    };
  
    return (
      <div>
        <Tabs activeTab={activeTab} changeTab={changeTab}  />
        <Content activeTab={activeTab} Swal={Swal} API_ROUTES={API_ROUTES} objectToFormData={objectToFormData} emailValidation={emailValidation} userData={userData} errorFunction={errorFunction} capitalizeWords={capitalizeWords} headers={headers} changeTab={changeTab} whitelistBtn={whitelistBtn} setWhitelistBtn={setWhitelistBtn} register3={register3} reset3={reset3} handleSubmit3={handleSubmit3} errors3={errors3} isSubmitting3={isSubmitting3} isValid3={isValid3} isEditing={isEditing} setIsEditing={setIsEditing} />
      </div>
    );
  }

export default MyTabs;