import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import UnSubscribersTable from "./SubscribersTable";
import { format } from "date-fns";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


export default function UnSubscribers(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired } = props;

    useEffect(() => {document.title = "UnSubscribers | Forthsoft"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(true);
    const [openDuration, setOpenDuration] = useState(false);
    const [openServiceNtwk, setOpenServiceNtwk] = useState(false);
    const [openNtwk, setOpenNtwk] = useState(false);
    const [openOthers, setOpenOthers] = useState(true);
    const [registerName, setRegisterName] = useState('service');
    const clearInputs = useRef(null);


    const columns1 = [
        {   
            name: 'Sender',
            selector: row => row.senderAddress ? row.senderAddress : <Nulls />,
            sortable: true,
        },
        {
            name: 'Receiver',
            selector: row => row.receiverAddress ? capitalizeWords(row.receiverAddress) : <Nulls />,
            sortable: true,
        },
        {   
            name: 'Network',
            cell: row => {
                let isNetwork = <div style={{ color:'orange' }}>MTN</div>
                if(row.network_id === 2){
                    isNetwork = <div style={{ color:'#72ae49' }}>GLO</div>
                }else if(row.network_id === 3){
                    isNetwork = <div style={{ color:'red' }}>AIRTEL</div>
                }else if(row.network_id === 4){
                    isNetwork = <div style={{ color:'#82AA40' }}>9MOBILE</div>
                }
                return <b>{isNetwork}</b>
            },
        },
        {
            name: 'User',
            selector: row => row.user_id,
        },
        {
            name: 'Message',
            selector: row => row.message,
        },
        {
            name: 'Date Created',
            selector: row => {
                return row.created_at ? format(new Date(row.created_at), "MMM d, yyyy h:mma") : <Nulls />;
            }
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const getData = async (isLoad=true, myFilters={}) => {
        let url = "";
        // url = `${API_ROUTES.VIEW_SERVICES}?page=${page}&perPage=${countPerPage}`;
        // if(network !== null || keyword !== null){
        //     url = `${API_ROUTES.VIEW_SERVICES}?page=${page}&perPage=${countPerPage}&network=${network}&keyword=${keyword}&price_point=${price_point}`;
        // }

        // url = `${API_ROUTES.VIEW_SERVICES}`;
        // if(network !== null || keyword !== null){
        //     url = `${API_ROUTES.VIEW_SERVICES}?network=${network}&keyword=${keyword}&price_point=${price_point}`;
        // }

        let dataString2 = {};

        const dataString1 = { 
            page: page,
        };
        if(registerName === "service"){
            dataString2 = { 
                service: myFilters.service,
            } 
        }else if(registerName === "network"){
            dataString2 = { 
                network: myFilters,
            } 
        }else if(registerName === "subscriber_address"){
            dataString2 = { 
                subscriber_address: myFilters.subscriber_address,
            } 
        }else if(registerName === "channel"){
            dataString2 = { 
                channel: myFilters.channel,
            } 
        }else if(registerName === "service_network"){
            dataString2 = { 
                service: myFilters.service,
                network: myFilters.network,
            } 
        }else if(registerName === "duration"){
            dataString2 = { 
                fro: myFilters.fro,
                to: myFilters.to,
            } 
        }
        const mergedObject = { ...dataString1, ...dataString2 };
        setIsFade(true);

        try {
            const response = await fetch(API_ROUTES.UNSUBSCRIBERS, {
                method: "POST",
                body: objectToFormData(mergedObject),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;
                setDataTotal(totalData);
                setDataList(responseData);
                setIsLoading(false);
                setIsFade(false);
            }else{
                setDataTotal(0);
                setDataList([]);
                setIsLoading(false);
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataTotal(0);
            setDataList([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }

    useEffect(() => {
        getData(true, "");
    }, [page]);

    const handleChanges = async (event) => {
        const filters = event.target.value;
    }

    const handleNetworkChange = async (event) => {
        const filters = event.target.value;
        getData(true, filters);
    }
    
    const handleAllNetworks = async (event) => {
        const filters = event.target.value;
    }
    
    const handleServiceNtwk = async (data) => {
        getData(true, data);
    }
    
    const handleOthers = async (data) => {
        getData(true, data);
    }
    
    const handleDuration = async (data) => {
        console.log(data);
        getData(true, data);
    }

    const handleFilters = async (event) => {
        const filters = event.target.value;
        setDataTotal(0);
        setDataList([]);
        // if (clearInputs.current) {
        //     clearInputs.current.value = '';
        // }

        setRegisterName(filters);

        setOpenNtwk(false);
        setOpenServiceNtwk(false);
        setOpenDuration(false);
        setOpenOthers(false);
        
        if(filters === "duration"){
            setOpenDuration(true);
        }
        if(filters === "service_network"){
            setOpenServiceNtwk(true);
        }
        if(filters === "network"){
            setOpenNtwk(true);
        }
        if(filters !== "service_network" && filters !== "duration" && filters !== "network"){
            setOpenOthers(true);
        }
    }

    const handleOthersChange = async (event) => {
        const filters = event.target.value;
        let datas = {};

        if(registerName === "service"){
            datas = {service: filters}
        }else if(registerName === "network"){
            datas = {network: filters}
        }else if(registerName === "subscriber_address"){
            datas = {subscriber_address: filters}
        }else if(registerName === "channel"){
            datas = {channel: filters}
        }
        getData(true, datas);
    }


    return (
        <>
            {/* <Loaders /> */}
            {/* <LoadingSpinner /> */}

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="row g-4">
                                        <div className="col-lg-12 pl-xs-0 pr-xs-0">
                                            <div className="card-header mb--20 mb-xs-10">
                                                <h2>UnSubscribers</h2>
                                            </div>

                                            <UnSubscribersTable capitalizeWords={capitalizeWords} dataList={dataList} dataTotal={dataTotal} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} handleChanges={handleChanges} isFade={isFade} handleFilters={handleFilters} openDuration={openDuration} openServiceNtwk={openServiceNtwk} openNtwk={openNtwk} openOthers={openOthers} handleServiceNtwk={handleServiceNtwk} handleAllNetworks={handleAllNetworks} handleNetworkChange={handleNetworkChange} handleDuration={handleDuration} handleOthers={handleOthers} registerName={registerName} handleOthersChange={handleOthersChange} clearInputs={clearInputs} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}