import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function DemandsTable(props) {
    const { capitalizeWords, isLoading, columns, dataTotal, dataList, countPerPage, setPage, handleChange1, isFade, handleNtwk } = props;

    const ExpandedComponent = ({ data }) => (
        <div className='text-left th_data'>
            <div><font>Service ID:</font> {data.serviceId ? data.serviceId : <Nulls />}</div>
            <div><font>Product Code:</font> {data.productCode ? data.productCode : <Nulls />}</div>
            <div><font>Type:</font> {data.type ? data.type : <Nulls />}</div>
            <div><font>Keyword:</font> <span style={{ textTransform: 'uppercase' }}>{data.keyword}</span></div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            
        </div>
    );


  return (
    <div className="App">

        {
            isLoading ? 
            <LoadingSpinner />
            :
            <>
                <FilterComponent handleChange={handleChange1} handleNtwk={handleNtwk} />

                <div style={{ opacity: isFade ? '0.3' : 1 }} >
                <DataTable
                    className="services_datatable users"
                    title=""
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={columns}
                    data={dataList}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={dataTotal.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                    noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />
                </div>
            </>
        }
        
    </div>
  );
}

export default DemandsTable;
