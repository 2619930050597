import React from 'react';
import { useForm } from "react-hook-form";


const FilterComponent = (props) => {
    const { handleChanges, handleFilters, openDuration, openServiceNtwk, openNtwk, openOthers, handleServiceNtwk, handleNetworkChange, handleDuration, handleOthers, registerName, handleOthersChange, clearInputs, handleAllNetworks } = props;

    const { register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1, isSubmitting: isSubmitting1, isValid: isValid1} } = useForm({mode: 'onChange'});

    const { register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2, isSubmitting: isSubmitting2, isValid: isValid2} } = useForm({mode: 'onChange'});

    const { register: register3, handleSubmit: handleSubmit3, formState: {errors: errors3, isSubmitting: isSubmitting3, isValid: isValid3} } = useForm({mode: 'onChange'});

    return (
        <div className='row'>
            <div className='offset-lg-5 col-lg-7'>
                <div className='col-sm-4 pr-0'>
                    <select onChange={handleFilters} className='filter_text'>
                        <option value="service" selected>Service</option>
                        <option value="network">Network</option>
                        <option value="subscriber_address">Subscriber Address</option>
                        <option value="duration">Duration</option>
                        <option value="service_network">Network & Service</option>
                        <option value="channel">Channel</option>
                    </select>
                </div>
                {
                    openDuration && (
                        <div className='col-sm-8 pr-0'>
                            <form onSubmit={handleSubmit1(handleDuration)}>
                                <div className='col-sm-5 pl-0 pr-0'>
                                    <input type="date" className='filter_text' placeholder={`Filter here`} {...register1('fro')} defaultValue={new Date().toISOString().substr(0, 10)}  />
                                </div>
                                <div className='col-sm-5 pl-5 pr-0'>
                                    <input type="date" className='filter_text' placeholder={`Filter here`} {...register1('to')} defaultValue={new Date().toISOString().substr(0, 10)}  />
                                </div>
                                <div className='col-sm-2 col-sm-2 p-0'>
                                    <button className="btn_1 btn-primary" type="submit" disabled={isSubmitting1 || !isValid1} style={{ opacity: isSubmitting1 ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    openServiceNtwk && (
                        <div className='col-sm-8 pl-0 pr-0'>
                            <form onSubmit={handleSubmit2(handleServiceNtwk)}>
                                <div className='col-lg-4 col-sm-4 pr-0'>
                                    <select className='filter_text' onChange={handleAllNetworks} {...register2('network')} 
                                    //ref={clearInputs} 
                                    >
                                        <option value="">-All-</option>
                                        <option value="MTN">MTN</option>
                                        <option value="GLO">GLO</option>
                                        <option value="AIRTEL">AIRTEL</option>
                                        <option value="9MOBILE">9MOBILE</option>
                                    </select>
                                </div>
                                <div className='col-lg-6 col-sm-6 pl-5 pr-5'>
                                    <input type="text" className='filter_text' placeholder={`Filter services`} {...register2('service')} 
                                    //ref={clearInputs} 
                                    />
                                </div>

                                <div className='col-lg-2 col-sm-2 p-0'>
                                    <button className="btn_1 btn-primary" type="submit" disabled={isSubmitting2 || !isValid2} style={{ opacity: isSubmitting2 ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                                </div>
                            </form>
                        </div>
                    )
                }

{
                    openNtwk && (
                        <div className='col-sm-8 pl-0 pr-0'>
                            {/* <form onSubmit={handleSubmit2(handleServiceNtwk)}> */}
                                <div className='col-lg-12 col-sm-12 pr-0'>
                                    <select className='filter_text' 
                                    //{...register2('network')} 
                                    onChange={handleNetworkChange}
                                    ref={clearInputs} 
                                    >
                                        <option value="">-Select-</option>
                                        <option value="MTN">MTN</option>
                                        <option value="GLO">GLO</option>
                                        <option value="AIRTEL">AIRTEL</option>
                                        <option value="9MOBILE">9MOBILE</option>
                                    </select>
                                </div>

                                {/* <div className='col-lg-2 col-sm-2 p-0'>
                                    <button className="btn_1 btn-primary" type="submit" disabled={isSubmitting2 || !isValid2} style={{ opacity: isSubmitting2 ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                                </div> */}
                            {/* </form> */}
                        </div>
                    )
                }

                {
                    openOthers && (
                        <div className='col-sm-8 pr-0'>
                            {/* <input type="text" className='filter_text' onChange={handleChanges} placeholder="Filter here" /> */}

                            {/* <form onSubmit={handleSubmit3(handleOthers)}> */}
                            <form>
                                <div className='col-lg-12 col-sm-10 pl-0 pr-0'>
                                    <input type="text" className='filter_text' ref={clearInputs} 
                                    onChange={handleOthersChange}
                                    // {...register3(registerName)} 
                                    placeholder="Filter here" />
                                </div>

                                {/* <div className='col-lg-2 col-sm-2 p-0'>
                                    <button className="btn_1 btn-primary" type="submit" disabled={isSubmitting2 || !isValid2} style={{ opacity: isSubmitting2 ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                                </div> */}
                            </form>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default FilterComponent;
