import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function ServicesTable(props) {
    const { capitalizeWords, isLoading, columns, dataTotal, dataList, countPerPage, setPage, handleChange1, isFade, handleNtwk } = props;

    const ExpandedComponent = ({ data }) => (
        <div className='text-left th_data'>
            <div><font>Product ID:</font> {data.productId ? data.productId : <Nulls />}</div>
            <div><font>Product Code:</font> {data.productCode ? data.productCode : <Nulls />}</div>
            <div><font>Keyword:</font> <span style={{ textTransform: 'uppercase' }}>{data.keyword},... <a href="#" style={{ fontSize: '15px', textTransform: 'none' }}>View more</a></span></div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
            
        </div>
    );

    
    // const [toggledClearRows, setToggledClearRows] = React.useState(false);
      
    // const handleClearRows = () => {
    //     setToggledClearRows(!toggledClearRows);
    // }



  return (
    <div className="App">

        <FilterComponent handleChange={handleChange1} handleNtwk={handleNtwk} />

        <div style={{ opacity: isFade ? '0.3' : 1 }} >
            {
                isFade ? 
                <LoadingSpinner /> 
                :
                <DataTable
                    className="services_datatable users"
                    title=""
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={columns}
                    data={dataList}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={dataTotal.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                    noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />

            }
        </div>
            
        
    </div>
  );
}

export default ServicesTable;
