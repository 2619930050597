import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { API_ROUTES } from '../../../utils/constants';


function RevenueReportTable(props) {
    const { capitalizeWords, isLoading, setIsLoading, columns, dataTotal, dataList, setDataList, countPerPage, setPage, isFade, setIsFade, isAuthExpired, objectToFormData, setColumns } = props;

    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [operatorList, setOperatorList] = useState([]);
    const [distinctList, setDistinctList] = useState([]);
    const [serviceProds, setServiceProds] = useState([]);
    const userData = JSON.parse(localStorage.getItem('userDataFs'));
    

    const ExpandedComponent = ({ data }) => (
        <div className='text-left th_data'>
            <div><font>Product ID:</font> {data.productId ? data.productId : <Nulls />}</div>
            <div><font>Product Code:</font> {data.productCode ? data.productCode : <Nulls />}</div>
            <div><font>Keyword:</font> <span style={{ textTransform: 'uppercase' }}>{data.keyword},... <a href="#" style={{ fontSize: '15px', textTransform: 'none' }}>View more</a></span></div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
        </div>
    );


    const getOperators = async () => {
        setIsLoading1(true);
        try {
            const response = await fetch(API_ROUTES.OPERATORS, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON);
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setOperatorList(responseData);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setOperatorList([]);
            setIsLoading1(false);
        }
    }

    const distinctServices = async () => {
        setIsLoading1(true);
        try {
            const response = await fetch(API_ROUTES.DISTINCT_SERVICES, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON);
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setDistinctList(responseData);
                setIsLoading1(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDistinctList([]);
            setIsLoading1(false);
        }
    }

    const serviceProducts = async (serviceId) => {
        setIsLoading2(true);
        const dataString = { 
            service_id: serviceId,
        };
        try {
            const response = await fetch(API_ROUTES.SERVICE_PRODUCTS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON);
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setServiceProds(responseData);
                setIsLoading2(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setServiceProds([]);
            setIsLoading2(false);
        }
    }


    
    const submitSearchQuery = async (startDate, endDate, oprt, distinct_service_id, product_id) => {
        setIsFade(true);
        const dataString = { 
            start_date: startDate,
            end_date: endDate.replace("00:00:00", "23:59:59"),
            operator: oprt,
            distinct_service_id,
            product_id,
        };
        try {
            const response = await fetch(API_ROUTES.SERVICE_REVENUE, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON);
            setIsFade(false);
            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const formattedStartDate = startDate.split(' ')[0];
                const formattedEndDate = endDate.split(' ')[0];

                let operatorName = "Unknown Operator";
                if (oprt === 1) {
                    operatorName = "MTN";
                } else if (oprt === 2) {
                    operatorName = "GLO";
                } else if (oprt === 3) {
                    operatorName = "Airtel";
                } else if (oprt === 4) {
                    operatorName = "9Mobile";
                }

                setDataList([
                    {   
                        date: <div className="text-right" style={{fontSize: '14px'}}><div><b>From:</b> {formattedStartDate}</div><div><b>To:</b> {formattedEndDate}</div></div>,
                        operator: operatorName,
                        service: distinct_service_id,
                        tariff: product_id,
                        counts: responseData.totalCount,
                        revenue: responseData.totalChargeAmount,
                    },
                ]);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            // setDataList([]);
            setIsFade(false);
        }
    }


    useEffect(() => {
        (async() => {
            getOperators();
            distinctServices();
        })()
    }, []);

    

  return (
    <div className="App">

        {
            isLoading1 ? 
            <LoadingSpinner />
            :
            <FilterComponent isAuthExpired={isAuthExpired} isLoading1={isLoading1} isLoading2={isLoading2} operatorList={operatorList} distinctList={distinctList} serviceProducts={serviceProducts} serviceProds={serviceProds} submitSearchQuery={submitSearchQuery} />
        }

        <div style={{ opacity: isFade ? '0.3' : 1 }} className='mt-3'>
            {
                isFade ? 
                <LoadingSpinner /> 
                :
                <DataTable
                    className="services_datatable users"
                    title=""
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={columns}
                    data={dataList}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={dataTotal.total}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                    noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />

            }
        </div>
            
        
    </div>
  );
}

export default RevenueReportTable;
