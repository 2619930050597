import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";
import Nulls from "../Nulls";
import RevenueReportTable from "./RevenueReportTable";


export default function RevenueReport(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired } = props;

    useEffect(() => {document.title = "Service Revenue Report | Forthsoft"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataList, setDataList] = useState({});
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(false);

    const columns1 = [
        {   
            name: 'Date',
            selector: row => row.date ? row.date : <Nulls />,
            sortable: true,
        },
        {
            name: 'Operator',
            selector: row => row.operator ? row.operator : <Nulls />,
            sortable: true,
        },
        {   
            name: 'Service',
            selector: row => row.service ? row.service : <Nulls />,
        },
        {
            name: 'Tariff',
            selector: row => row.tariff ? row.tariff : <Nulls />,
        },
        {   
            name: 'Charged Count',
            selector: row => row.counts ? parseFloat(row.counts).toLocaleString() : '0.00',
            sortable: true,
        },
        {   
            name: 'Total Revenue',
            selector: row => row.revenue ? '₦'+parseFloat(row.revenue).toLocaleString() : '0.00',
            sortable: true,
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);


    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const today = new Date();

    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    const formatDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    function resetTimeToMidnight(dateTimeStr) {
        const [date, _] = dateTimeStr.split(' ');
        return `${date} 00:00:00`;
    }
    function resetTimeToB4Midnight(dateTimeStr) {
        const [date, _] = dateTimeStr.split(' ');
        return `${date} 23:59:59`;
    }


    const getData = async () => {
        const dataString = { 
            start_date: resetTimeToMidnight(formatDate(yesterday)),
            end_date: resetTimeToB4Midnight(formatDate(today)),
        };
        console.log(dataString);
        setIsFade(true);
        try {
            const response = await fetch(API_ROUTES.SERVICE_REVENUE, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });

            const responseJSON = await response.json();
            isAuthExpired(responseJSON);

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                setDataList([
                    {   
                        date: <div className="text-right" style={{fontSize: '14px'}}><div><b>From:</b> {dataString.start_date}</div><div><b>To:</b> {dataString.end_date}</div></div>,
                        operator: 'MTN',
                        service: null,
                        tariff: null,
                        counts: responseData.totalCount,
                        revenue: responseData.totalChargeAmount,
                    },
                ]);
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataList([]);
            setIsFade(false);
        }
    }

    
    useEffect(() => {
        getData(true, "", "", "");
    }, [page]);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="row g-4">
                                        <div className="col-lg-12 pl-xs-0 pr-xs-0">
                                            <div className="card-header mb--20 mb-xs-10">
                                                <h2>Service Revenue Report</h2>
                                            </div>

                                            <RevenueReportTable capitalizeWords={capitalizeWords} dataList={dataList} setDataList={setDataList} dataTotal={dataTotal} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} isFade={isFade} setIsFade={setIsFade} isAuthExpired={isAuthExpired} objectToFormData={objectToFormData} setColumns={setColumns} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}