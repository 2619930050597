import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { API_ROUTES } from '../../../utils/constants';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import LoadingSpinnerSmall from '../LoadingSpinner/LoadingSpinnerSmall';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../libs/css/date-picker.css';




const FilterComponent = (props) => {
    const { handleChange, isAuthExpired, isLoading2, operatorList, distinctList, serviceProducts, serviceProds, submitSearchQuery } = props;
    const userData = JSON.parse(localStorage.getItem('userDataFs'));
    const { register, handleSubmit, formState: {errors: errors2, isSubmitting, isValid} } = useForm({mode: 'onChange'});

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [opts, setOpts] = useState('');
    const [services, setServices] = useState('');
    const [tariffs, setTariffs] = useState('');

    const formatDate = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleStartDateChange = (date) => {
        setSelectedDate(formatDate(date));
    };

    const handleEndDateChange = (date) => {
        setSelectedDate1(formatDate(date));
    };

    const handleServices = async (event) => {
        const selectedValue = event.target.value;
        await serviceProducts(selectedValue);
        setServices(selectedValue);
    };

    const handleOperator = (event) => {
        setOpts(event.target.value);
    };

    const handleTariff = (event) => {
        setTariffs(event.target.value);
    };

    const searchQuery = async () => {
        const startDate = selectedDate || '';
        const endDate = selectedDate1 || '';
        const oprt = opts || '';
        const distinct_service_id = services || '';
        const product_id = tariffs || '';
        await submitSearchQuery(startDate, endDate, oprt, distinct_service_id, product_id);
    };


    useEffect(() => {
        if (operatorList.length >= 1) {
            setOpts(operatorList[0].id);
        }
    }, [operatorList]);




    return (
        <>
            <div className='row'>
                <div className='offset-md-6 col-md-3 offset-sm-6 col-sm-3 pr-0'>
                    <DatePicker
                        selected={selectedDate}
                        className='filter_text'
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        placeholderText="Start Date"
                        calendarClassName="large-calendar"  
                    />
                </div>
                
                <div className='offset-md-0 col-md-3 offset-sm-0 col-sm-3 pl-0'>
                    <DatePicker
                        selected={selectedDate1}
                        className='filter_text'
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        placeholderText="End Date"
                        calendarClassName="large-calendar"  
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-2 col-md-2'>
                    <select onChange={handleOperator} className='filter_text' value={opts}>
                        <option value="">-Operator (All)-</option>
                        {
                            operatorList.length !== 0 && operatorList.map(operator => { 
                                return <option key={operator.id} value={operator.id}>{operator.network}</option>
                            })
                        }
                    </select>
                </div>

                <div className='col-lg-5 col-md-5 pl-0'>
                    <select onChange={handleServices} className='filter_text'>
                        <option value="">-Service (All)-</option>
                        {
                            distinctList.length !== 0 && distinctList.map(item => { 
                                return <option key={item.serviceId} value={item.serviceId}>{item.serviceName}</option>
                            })
                        }
                    </select>
                </div>

                <div className='col-lg-4 col-md-4 pl-0'>
                    {isLoading2 && <LoadingSpinnerSmall /> }
                    <select onChange={handleTariff} className='filter_text' disabled={isLoading2} style={{ opacity: isLoading2 ? 0.4 : 1}}>
                        <option value="">-Tariff (All)-</option>
                        {
                            serviceProds.length !== 0 && serviceProds.map(item => { 
                                return <option key={item.productId} value={item.productId}>{item.product_name}</option>
                            })
                        }
                    </select>
                </div>

                <div className='col-lg-1 col-md-1 pl-0'>
                    <button className="btn_1 btn-primary" type="button" onClick={searchQuery} disabled={isSubmitting || !isValid} style={{ opacity: isSubmitting ? '0.5' : 1 }}><i className='fa fa-search'></i></button>
                </div>
            </div>
        </>
    );
};

export default FilterComponent;
