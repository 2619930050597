import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../../../libs/css/dataTable.css';
import Nulls from '../Nulls';
import FilterComponent from './FilterComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function SubscribersTable(props) {
    const { capitalizeWords, isLoading, columns, dataTotal, dataList, countPerPage, setPage, handleChanges, isFade, handleFilters, openDuration, openServiceNtwk, openNtwk, openOthers, handleServiceNtwk, handleNetworkChange, handleDuration, handleOthers, registerName, handleOthersChange, clearInputs, handleAllNetworks } = props;

    const ExpandedComponent = ({ data }) => (
        <div className='text-left th_data'>
            {/* <div><font>Service Name:</font> {data.service_id ? data.service_id : <Nulls />}</div> */}
            <div><font>Subscriber ID:</font> {data.subscriber_id ? data.subscriber_id : <Nulls />}</div>
            <div><font>Date Created:</font> {data.created_at ? format(new Date(data.created_at), 'MMM d, yyyy h:mma') : <Nulls />}</div>
        </div>
    );


    return (
        <div className="App">
            {
                isLoading ? 
                <LoadingSpinner />
                :
                <>
                    
                    <FilterComponent handleChanges={handleChanges} handleFilters={handleFilters} openDuration={openDuration} openServiceNtwk={openServiceNtwk} openNtwk={openNtwk} openOthers={openOthers} handleServiceNtwk={handleServiceNtwk} handleAllNetworks={handleAllNetworks} handleNetworkChange={handleNetworkChange} handleDuration={handleDuration} handleOthers={handleOthers} registerName={registerName} handleOthersChange={handleOthersChange} clearInputs={clearInputs} />

                    <div style={{ opacity: isFade ? '0.3' : 1 }} >
                    <DataTable
                        className="services_datatable users"
                        title=""
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        columns={columns}
                        data={dataList}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={dataTotal}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                        noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page)}
                    />

                    {dataTotal <= 0 && <div className='mt-20'>Please filter on the filter boxes above to populate data</div>} 
                    </div>
                </>
            }
            
        </div>
    );
}

export default SubscribersTable;
